<template>
  <Layout :footer-style="1">
    <div class="container">
      <Breadcrumb root-url="/software-service" parent-url="软件服务" current-url="反垃圾" style="border: none;"/>
    </div>

    <div class="rn-service-area rn-section-gapBottom pt--100 bg-color-light area-2">
      <div class="container">
        <div class="product">
          <img class="pic" src="@/assets/images/product/img.png" />
          <div class="about">
            <div class="title">反垃圾</div>
            <div class="content">
              网络反垃圾，智能策略定制，高效过滤黄色，暴恐，违禁等不良信息，多年反垃圾实践，匠心服务保障。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Start Service Area -->
    <div class="rn-service-area rn-section-gapBottom pt--60 area-2">
      <div class="container">

        <div class="row">
          <div class="col-lg-12">
            <div class="section-title" data-aos="fade-up">
              <h3 class="title color-gray">守内安</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <img class="company-logo" src="@/assets/images/brand/brand-34.png">
            <div class="company-info">
              Softnext守内安有着专业的网络内容安全应用方案的研发团队，10年来专注于邮件安全以及网络内容安全的风险管控，及时提供应对最新威胁的高科技网络产品；作为内容安全管理及网络应用服务的软件专业研发厂商，守内安所开发的具有领导地位的Email内容安全服务方案：【SPAM SQR垃圾邮件过滤管理专家】、【Mail SQR Expert全方位电子邮件保全专家】为最多客户所爱用和好评，两个产品皆获得中国计算机用户协会所颁发的【2008中国计算机信息防护解决方案“优秀奖”】；MSE更是获得【2009《网管员世界》编辑选择奖】的多重肯定。为遵循法规、强化网内安控需求，守内安信息科技推出【Mail Archiving Expert电子邮件归档专家】，提供最佳管理效能及投资效益比的Email归档管理服务。为了加强企业网络应用的完整性，产品开发延伸到WebMail、Web、IM、P2P、FTP..等多通讯端口的内容安全管理，则推出【Content SQR网络控制审计管理专家】。经由10年的整合及应用开发，练就了今日最完整且专业的邮件内容安全解决方案。
            </div>
          </div>
        </div>

        <Separator style="margin: 80px 0;"/>

        <div class="row" data-aos="fade-up">
          <div class="col-lg-12">
            <div class="section-title">
              <h3 class="title color-gray">MailData</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <img class="company-logo" src="@/assets/images/brand/brand-35.png">
            <div class="company-info">
              北京易信源信息技术有限公司是一家总部设立于北京中关村园区的高科技软件企业。公司专注于电子邮件数据管理领域的技术研究和产品开发，致力于为广大用户提供世界顶级的先进技术和整体化解决方案，全面解决包括邮件数据的收集保存、查询调阅、快速恢复、统计分析、容灾备份、安全保护、整理利用等各方面问题。
              作为业界知名的邮件管理专家，易信源的核心研发人员多年来不断关注电子邮件领域的技术发展与研究，很早就注意到当前邮件应用领域数据安全及管理方面存在的诸多问题，凭借着丰富的产品开发经验和大胆创新的精神，形成了一套独特的产品设计思路，并原创开发了“动态邮件流处理技术”等多项拥有自主知识产权的专利技术，为最终形成世界领先的邮件数据管理产品打下了坚实的基础。
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- End Service Area -->
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'
import Icon from '@/components/icon/Icon'
import Separator from '@/components/elements/separator/Separator'
import SectionTitle from '@/components/elements/sectionTitle/SectionTitle'
import Breadcrumb from '@/components/elements/breadcrumb/Breadcrumb'
export default {
  name: 'Product',
  components: {
    SectionTitle,
    Separator,
    Icon,
    Breadcrumb,
    Layout
  },
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.display-block{
  display: block;
}
.area-2{
  .icon {
    .p-img {
      width: 64px;
      height: 64px;
    }
  }
  .more{
    display: inline-flex;
    color: #2261AA;
    img {
      width: 27px;
      height: 27px;
    }
    .i1{
      margin-left: -4px;
    }
    .i2{
      margin-left: -20px;
    }
  }
}
.product{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  .pic{
    height: 220px;
  }
  .about{
    margin-left: 40px;
    color: #000000;
    .title{
      font-size: 32px;
      font-weight: bold;
    }
    .content{
      margin-top: 12px;
      font-size: 20px;
    }
  }
}
.company-logo{
  width: 320px;
}
.company-info{
  font-size: 24px;
  color: #000000;
  text-align: left;
  margin-top: 20px;
}
</style>
